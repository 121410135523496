module.exports = [{
      plugin: require('/Users/ycui/ws/yanglucellocom/website/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":""},
    },{
      plugin: require('/Users/ycui/ws/yanglucellocom/website/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Yang Lu 陸楊","short_name":"","start_url":"/","background_color":"#ffffff","theme_color":"#663399","display":"minimal-ui","icon":"content/assets/gatsby-icon.png"},
    },{
      plugin: require('/Users/ycui/ws/yanglucellocom/website/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/ycui/ws/yanglucellocom/website/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
